import React, { useState, useContext, useEffect } from "react";

import PsContext from "../../context";
import { Link } from "react-router-dom";

const MobileNumberExistAlertMessage = (props) => {
  const context = useContext(PsContext);

  return (
    <>
      <div className="alert alert-danger">
        Sorry the given mobile number is already registerd.
      </div>
      Please <Link to="/sign-in">Click Here</Link> to login your account.
      <hr />
      OR using different Mobile number to continue registration.
    </>
  );
};

export default MobileNumberExistAlertMessage;
