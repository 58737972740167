import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const inputField2 = (props) => {
  return (
    <>
      <Row className="mt-2">
        <Col md={9}>
          <label>{props.label}</label>
        </Col>
        <Col md={3}>
          <Form.Control
            size="sm"
            className="fw-bold"
            readOnly
            value={props.value}
          />
        </Col>
      </Row>
    </>
  );
};

export default inputField2;
