import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";

import StepThree from "../newRegistration/stepThree";

const EditCertificates = (props) => {
  const context = useContext(PsContext);

  return (
    <>
      <StepThree pageType="edit" {...props} />
    </>
  );
};

export default EditCertificates;
