import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PsContext from "../../../context";
import { printDocument, upperCase } from "../../../utils";
import { TABLE_STYLES } from "../../../utils/data";

const ViewAdmissionApplication = (props) => {
  const context = useContext(PsContext);

  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const field = (fieldName) => {
    if (props.dataSource && props.dataSource[fieldName])
      return props.dataSource[fieldName];
  };

  useEffect(() => {
    printDocument("view_print");

    if (props.onSuccess) props.onSuccess();
  }, [props]);

  return (
    <>
      <div style={{ display: "none" }}>
        <div id="view_print">
          <table
            width="100%"
            align="center"
            style={TABLE_STYLES.tableCollapse}
            className="font-bookman"
            cellPadding={10}
          >
            <thead>
              <tr>
                <th colSpan={12} align="center" height="30">
                  <b>{context.settingValue("print_header_name")}</b>
                  <br />
                  {context.settingValue("billheader_addresscity")}
                  <br />
                  {context.settingValue("billheader_addresslineone")}
                </th>
              </tr>
              <tr style={TABLE_STYLES.borderBottom}></tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "250px" }}>
                  {" "}
                  Application Form For {upperCase(field("course_type"))} Courses
                </td>
                <td> {field("course")}</td>
              </tr>
              <tr>
                <td> Name of the applicant :</td>
                <td> {field("application_name")}</td>
              </tr>
              <tr>
                <td> Date of Birth :</td>
                <td> {field("dob")}</td>
              </tr>
              <tr>
                <td> Mobile Number :</td>
                <td> {field("mobile_no")}</td>
              </tr>
              <tr>
                <td> E-mail :</td>
                <td> {field("email")}</td>
              </tr>
              <tr>
                <td> Aadhar Card Number :</td>
                <td> {field("aadhar_no")}</td>
              </tr>
              {field("school_name") != "" && (
                <tr>
                  <td> School Name :</td>
                  <td> {field("school_name")}</td>
                </tr>
              )}
              {field("college_name") != "" && (
                <tr>
                  <td> College Name :</td>
                  <td> {field("college_name")}</td>
                </tr>
              )}
              {field("12thgroup_section") != "" && (
                <tr>
                  <td> 12th Group :</td>
                  <td>
                    {field("12thgroup_section") == 1
                      ? "Group 1 - Maths, Physics, Chemistry, Biology"
                      : ""}
                    {field("12thgroup_section") == 2
                      ? "Group 2 - Maths, Physics, Chemistry, Computer Science"
                      : ""}
                    {field("12thgroup_section") == 3
                      ? "Group 3 - Physics, Chemistry, Zoology, Botany"
                      : ""}
                    {field("12thgroup_section") == 4
                      ? "Group 4 - Commerce, Accountancy, any two in Core Subjects"
                      : ""}
                    {field("12thgroup_section") == 5 ? "Other Groups" : ""}
                  </td>
                </tr>
              )}

              <tr>
                <td> Course Selection 1 :</td>
                <td> {field("course_section_1")}</td>
              </tr>
              <tr>
                <td> Course Selection 2 :</td>
                <td> {field("course_section_2")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default withRouter(ViewAdmissionApplication);
