import { Spin } from "antd";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import PsContext from "../../../context";
import { listAllDisabilityCategory } from "../../../models/fieldSettings";
import {
  ANNUAL_INCOME_RANGE,
  DB_POSTFIX,
  DIOCESES_LIST,
  PARENTAL_STATUS,
  PLACEMENT_INTERESET_LIST,
  SPORTS_LEVEL,
  WORK_NATURE,
  capitalizeFirst,
  decimalKeyPress,
  indEmisNoKeyPress,
  integerAadhar,
  integerIndMobile,
  integerIndPincode,
  lowerCase,
  momentDate,
  numberToMonth,
  psAlert,
  upperCase,
} from "../../../utils";
import {
  BLOOD_GROUPS,
  COMMUNITIES,
  LANGUAGES,
  RELIGIONS,
} from "../../../utils/data";
import { ServiceUrl } from "../../../utils/serviceUrl";
import ConfirmModal from "../../confirmModal";
import StepMenu from "../stepMenu";

const StepOne = (props) => {
  const context = useContext(PsContext);

  const [loader, setLoader] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const [validated, setValidated] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const [selectedFile, setSelectedFile] = useState("");
  const [selectedReligion, setSelectedReligion] = useState("");
  const [selectedCommunity, setSelectedCommunity] = useState("");
  const [fatherStatus, setFatherStatus] = useState("");
  const [motherStatus, setMotherStatus] = useState("");
  const [parentalStatus, setParetalStatus] = useState("");

  const [specialReservation, setSpecialReservation] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [captcha, setCaptcha] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [disabilityList, setDisabilityList] = useState([]);

  const [denomination, setDenomination] = useState("");

  const [board, setBoard] = useState(null);

  useEffect(() => {
    loadCaptcha();
    if (props.pageType && props.pageType == "edit") loadData();

    listAllDisabilityCategory().then((res) => res && setDisabilityList(res));
  }, []);

  const loadData = () => {
    setDataLoader(true);
    setLoader(true);
    const form = new FormData();
    form.append("sadb", DB_POSTFIX);
    form.append("api_code", context.user.api_code);
    axios.post(ServiceUrl.UG.PERSONAL_MARKS_PREVIEW, form).then((res) => {
      if (res["data"].status == "1") {
        let d = res["data"].data.basic;
        setBaisc(res["data"].data.basic);
        setMarks(res["data"].data.marks);

        setSpecialReservation({
          physical: d.physically_challanged,
          exservice: d.ex_army_child,
          kashmire: d.citizen_from_jammu,
          sriLankan: d.srilankan_citizen,
          nri: d.is_nri,
          inSports: d.in_sports,
          inNcc: d.in_ncc,
          inNss: d.in_nss,
          ews: d.is_ews,
          firstgraduate: d.first_graduate,
          hostel: d.need_hostel,
        });

        setSelectedReligion(d.religion);
        setSelectedCommunity(d.community);
        setSelectedCountry(d.country);
        setDenomination(d.denomination);
        setSelectedState(d.state);
        setFatherStatus(d.father_status);
        setMotherStatus(d.mother_status);
        setParetalStatus(d.parental_status);
        setBoard(d.board);
        setDataLoader(false);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  const basicField = (fieldName) => {
    if (basic && basic[fieldName]) return basic[fieldName];
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      //toast.error("Please fill all the required fields.");
      psAlert(
        "Alert",
        `<div class="alert alert-danger bg-red-100">
          Please ensure all the fields are filled.
        </div>`
      );
      return;
    }
    setShowConfirm(true);
  };

  const submitForm = () => {
    setLoader(true);
    setShowConfirm(false);
    let url = ServiceUrl.UG.STEP_ONE;
    if (props.pageType && props.pageType == "edit")
      url = ServiceUrl.UG.UPDATE_STEP_ONE;

    axios.post(url, $("#frmStepI").serialize()).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");
        context.updateUser(res["data"].user);
        setLoader(false);
        setIsRedirect(true);
        if (props.pageType && props.pageType == "edit") props.history.push("/");
      } else {
        toast.error(res["data"].message || "Error");
        context.setLoader(false);
        setLoader(false);
        loadCaptcha();
      }
    });
  };

  const loadCaptcha = () => {
    try {
      setLoader(true);
      axios.get(ServiceUrl.ADMISSION.CAPTCHA).then((res) => {
        setCaptcha(res["data"]);
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const handleInitialChange = (e) => {
    if (e.target.value.length >= 3) {
      e.preventDefault();
      return;
    }
  };

  const days = (v = false) => {
    let rv = [];
    Array.from({ length: 31 }, (v, i) => {
      rv.push(
        <option value={i + 1} selected={i + 1 == v ? "selected" : ""}>
          {i + 1}
        </option>
      );
    });
    return rv;
  };

  const months = (v = false) => {
    let rv = [];
    Array.from({ length: 12 }, (v, i) => {
      let n = ("0" + (i + 1)).slice(-2);
      let n2 = ("0" + n).slice(-2);
      rv.push(
        <option value={n2} selected={n2 == v ? "selected" : ""}>
          {numberToMonth(n)}
        </option>
      );
    });
    return rv;
  };

  const years = (v = false) => {
    let rv = [];
    Array.from({ length: 9 }, (v, i) => {
      let n = new Date().getFullYear() - 16 - i;
      rv.push(
        <option value={n} selected={n == v ? "selected" : ""}>
          {n}
        </option>
      );
    });
    return rv;
  };

  const handleParentalStatusChange = (e) => {
    let v = e.target.value;
    setParetalStatus(v);
  };

  useEffect(() => {
    if (parentalStatus == "WITH PARENTS") {
      setFatherStatus(1);
      setMotherStatus(1);
    } else if (parentalStatus == "SINGLE PARENT - MOTHER ONLY") {
      setMotherStatus(1);
      setFatherStatus(0);
    } else if (parentalStatus == "SINGLE PARENT - FATHER ONLY") {
      setMotherStatus(0);
      setFatherStatus(1);
    } else if (parentalStatus == "ORPHAN") {
      setMotherStatus(0);
      setFatherStatus(0);
    }
  }, [parentalStatus]);

  return (
    <>
      <div className="container mb-3">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <StepMenu activeKey="step1" pageType={props.pageType} />
            {dataLoader && (
              <div className="text-center my-5">
                <Spinner />
              </div>
            )}
            {!dataLoader && (
              <Card>
                <Card.Body>
                  <Spin spinning={loader}>
                    <Form
                      noValidate
                      validated={validated}
                      encType="multipart/form-data"
                      id="frmStepI"
                      method="post"
                      onSubmit={handleFormSubmit}
                    >
                      <Row className="context_box_title">
                        <Col md={12}>
                          <h5>Personal Details :</h5>
                        </Col>
                      </Row>
                      <input
                        type="hidden"
                        name="i__userId"
                        value={context.user.user_id}
                      />
                      <input
                        type="hidden"
                        name="i__userCode"
                        value={context.user.user_code}
                      />
                      <input
                        type="hidden"
                        name="i__apiCode"
                        value={context.user.api_code}
                      />
                      <input type="hidden" name="sadb" value={DB_POSTFIX} />

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Board <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Check
                            type="radio"
                            inline
                            name="i__board"
                            label="State Board"
                            value="state"
                            defaultChecked={
                              basicField("board") == "state" ? true : false
                            }
                            onChange={(e) => setBoard("state")}
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__board"
                            label="CBSE"
                            value="cbse"
                            defaultChecked={
                              basicField("board") == "cbse" ? true : false
                            }
                            onChange={(e) => setBoard("cbse")}
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__board"
                            label="ICSE"
                            value="icse"
                            defaultChecked={
                              basicField("board") == "icse" ? true : false
                            }
                            onChange={(e) => setBoard("icse")}
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__board"
                            label="ISE"
                            value="ise"
                            defaultChecked={
                              basicField("board") == "ise" ? true : false
                            }
                            onChange={(e) => setBoard("ise")}
                            required
                          />
                        </Col>
                      </Row>

                      {/* <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Year of Passing{" "}
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Check
                            type="radio"
                            inline
                            name="i__passingYear"
                            label="2019 (or) After 2019"
                            value="2019"
                            defaultChecked={
                              basicField("year_of_passing") == "2019"
                                ? true
                                : false
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__passingYear"
                            label="Before 2019"
                            value="2018"
                            defaultChecked={
                              basicField("year_of_passing") == "2018"
                                ? true
                                : false
                            }
                            required
                          />
                        </Col>
                      </Row> */}

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Candidate Name
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={2}>
                          <Form.Control
                            type="text"
                            size="sm"
                            className="text-uppercase fw-bold"
                            required
                            placeholder="Initial"
                            name="i__initial"
                            defaultValue={context.user.initial}
                            onKeyPress={(e) => handleInitialChange(e)}
                          />
                        </Col>
                        <Col md={7}>
                          <Form.Control
                            type="text"
                            size="sm"
                            className="text-uppercase fw-bold"
                            required
                            placeholder="Name"
                            name="i__name"
                            defaultValue={context.user.name}
                          />
                        </Col>
                        <Col md={3}></Col>
                        <Col md={9}>
                          <label
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            (As per 12th Marksheet)
                          </label>
                        </Col>
                      </Row>

                      {context.settingValue("ug_tamil_name") == "1" && (
                        <Row className="py-2 border-bottom">
                          <Col md={3}>
                            <label>
                              Candidate Name in Tamil
                              <span className="text-danger">*</span>
                            </label>
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              type="text"
                              size="sm"
                              className="text-uppercase fw-bold"
                              required
                              placeholder="பெயரை தமிழில் உள்ளிடவும்"
                              name="name_in_tamil"
                              defaultValue={basicField("name_in_tamil")}
                            />
                            <a
                              href="https://www.google.com/intl/ta/inputtools/try/"
                              target="_blank"
                              className="mt-2"
                              style={{ textDecoration: "underline" }}
                            >
                              Tamil typing tool
                            </a>{" "}
                            (Please utilize the typing tool to input name in
                            Tamil, then copy and paste the resulting value into
                            above text box.)
                          </Col>
                        </Row>
                      )}

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Gender
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          {context.settingValue(
                            "admission_enable_gender_male_ug"
                          ) == "1" && (
                            <Form.Check
                              type="radio"
                              inline
                              name="i__gender"
                              label="Male"
                              value="male"
                              defaultChecked={
                                basicField("gender") == "male" ? true : false
                              }
                              required
                            />
                          )}
                          {context.settingValue(
                            "admission_enable_gender_female_ug"
                          ) == "1" && (
                            <Form.Check
                              type="radio"
                              inline
                              name="i__gender"
                              label="Female"
                              value="female"
                              defaultChecked={
                                basicField("gender") == "female" ? true : false
                              }
                              required
                            />
                          )}
                          <Form.Check
                            type="radio"
                            inline
                            name="i__gender"
                            label="Transgender"
                            value="transgender"
                            defaultChecked={
                              basicField("gender") == "transgender"
                                ? true
                                : false
                            }
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Date of Birth
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Control
                            as="select"
                            name="i__dobDay"
                            className="fw-bold form-select form-select-sm"
                            defaultValue={momentDate(
                              basicField("date_of_birth"),
                              "DD"
                            )}
                            required
                          >
                            <option value="">Day</option>
                            {Array.from({ length: 31 }, (v, i) => (
                              <option value={("0" + (i + 1)).slice(-2)}>
                                {i + 1}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col md={3}>
                          <Form.Control
                            as="select"
                            name="i__dobMonth"
                            className="fw-bold form-select form-select-sm"
                            defaultValue={momentDate(
                              basicField("date_of_birth"),
                              "MM"
                            )}
                            required
                          >
                            <option value="">Month</option>
                            {Array.from({ length: 12 }, (v, i) => (
                              <option value={("0" + (i + 1)).slice(-2)}>
                                {moment(i + 1, "M").format("MMMM")}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                        <Col md={3}>
                          <Form.Control
                            as="select"
                            name="i__dobYear"
                            className="fw-bold form-select form-select-sm"
                            defaultValue={momentDate(
                              basicField("date_of_birth"),
                              "YYYY"
                            )}
                            required
                          >
                            <option value="">Year</option>
                            {years()}
                          </Form.Control>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Aadhar No <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="text"
                            name="i__aadhar"
                            size="sm"
                            className="fw-bold"
                            placeholder="Aadhar No"
                            onKeyPress={(e) => integerAadhar(e)}
                            defaultValue={basicField("aadhar")}
                            required
                          />
                        </Col>
                      </Row>

                      {board == "state" && (
                        <Row className="py-2 border-bottom">
                          <Col md={3}>
                            <label>
                              EMIS NO
                              {context.settingValue(
                                "admission_ug_emisno_optional"
                              ) == "0" && (
                                <span className="text-danger">*</span>
                              )}
                            </label>
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              type="text"
                              name="i__emisNo"
                              size="sm"
                              className="fw-bold"
                              onKeyPress={(e) => indEmisNoKeyPress(e)}
                              defaultValue={basicField("emisno")}
                              placeholder="Emis No"
                              required={
                                context.settingValue(
                                  "admission_ug_emisno_optional"
                                ) == "0"
                                  ? true
                                  : false
                              }
                            />
                            <span className="text-danger">
                              If you do not have EMIS Number, please contact
                              your school and get it.
                            </span>
                          </Col>
                        </Row>
                      )}

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            ABC Id
                            <span className="text-danger">
                              {board != "state" && "*"}
                            </span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="text"
                            name="abc_id"
                            onKeyPress={(e) => integerAadhar(e)}
                            defaultValue={basicField("abc_id")}
                            placeholder="Academic Bank of Credits: ABC ID"
                            size="sm"
                            className="fw-bold"
                            required={board != "state" ? true : false}
                          />
                          <a
                            href="https://digilocker.meripehchaan.gov.in/signin/oauth_partner/%252Foauth2%252F1%252Fconsent%253Flogo%253D%2526amr%253Dpan%252Bdriving_licence%252Baadhaar%2526response_type%253Dcode%2526client_id%253D08627FE5%2526state%253D102%2526redirect_uri%253Dhttps%25253A%25252F%25252Fabc.digilocker.gov.in%25252Fsignup%25252Fcallback_data%2526scope%253Dopenid%252Bpicture%2526orgid%253D002585%2526txn%253D66275c8876548oauth21713855624%2526hashkey%253D45de64da8cf9bbc5c68f55ee42264c14720f7fb5acc9ebb779ff0aca73f02cdd%2526requst_pdf%253DY%2526app_name%253DQWNhZGVtaWMgQmFuayBvZiBDcmVkaXRz%2526signup%253Dsignup"
                            target="_blank"
                            style={{ textDecoration: "underline" }}
                          >
                            Create ABC Id
                          </a>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Community
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            as="select"
                            name="i__community"
                            value={lowerCase(selectedCommunity)}
                            onChange={(e) =>
                              setSelectedCommunity(e.target.value)
                            }
                            size="sm"
                            className="fw-bold form-select form-select-sm"
                            required
                          >
                            <option value="">-Select-</option>
                            {COMMUNITIES.map((item) => (
                              <option value={item.value}>{item.text}</option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Caste
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="text"
                            name="i__caste"
                            size="sm"
                            className="fw-bold text-uppercase"
                            placeholder="Caste"
                            defaultValue={basicField("caste")}
                            required
                          />
                        </Col>
                      </Row>

                      {upperCase(selectedCommunity) == "OC" && (
                        <Row className="py-2 border-bottom">
                          <Col md={9}>
                            <label>
                              Whether the applicant belongs to the Economically
                              Weaker Section (EWS)?
                              <span className="text-danger">*</span>
                            </label>
                          </Col>
                          <Col md={3}>
                            <Form.Check
                              type="radio"
                              inline
                              name="i_isEws"
                              label="Yes"
                              value="1"
                              defaultChecked={specialReservation.ews == 1}
                              onChange={(e) =>
                                setSpecialReservation({
                                  ...specialReservation,
                                  ews: 1,
                                })
                              }
                              required
                            />
                            <Form.Check
                              type="radio"
                              inline
                              name="i_isEws"
                              label="No"
                              value="0"
                              defaultChecked={specialReservation.ews == 0}
                              onChange={(e) =>
                                setSpecialReservation({
                                  ...specialReservation,
                                  ews: 0,
                                })
                              }
                              required
                            />
                          </Col>
                          <Col md={12} className="text-end">
                            {specialReservation.ews == "1" && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "13px" }}
                              >
                                You must upload the Community Certificate &
                                Income Certificate at Step-II.
                              </span>
                            )}
                          </Col>
                        </Row>
                      )}

                      {(selectedCommunity != "oc" ||
                        specialReservation.ews == "1") && (
                        <>
                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Community Certificate Number
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                type="text"
                                className="text-uppercase fw-bold"
                                name="community_certificate_no"
                                placeholder="Community Certificate Number"
                                defaultValue={basicField(
                                  "community_certificate_no"
                                )}
                                size="sm"
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Community Certificate Issue Date
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                type="date"
                                className="text-uppercase fw-bold"
                                name="community_certificate_issue_on"
                                size="sm"
                                defaultValue={basicField(
                                  "community_certificate_issue_on"
                                )}
                                max={momentDate(new Date(), "YYYY-MM-DD")}
                                required
                              />
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Religion
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            as="select"
                            name="i__religion"
                            size="sm"
                            className="fw-bold form-select form-select-sm"
                            defaultValue={basicField("religion")}
                            onChange={(e) =>
                              setSelectedReligion(e.target.value)
                            }
                            required
                          >
                            <option value="">-Select-</option>
                            {RELIGIONS.map((item) => (
                              <option value={item}>
                                {capitalizeFirst(item)}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>

                      {context.settingValue(
                        "admission_religion_with_christ_denomination"
                      ) == "1" &&
                        lowerCase(selectedReligion) == "christian" && (
                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Denomination
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                as="select"
                                name="i__denomination"
                                size="sm"
                                className="fw-bold form-select form-select-sm"
                                defaultValue={basicField("denomination")}
                                onChange={(e) =>
                                  setDenomination(e.target.value)
                                }
                                required
                              >
                                <option value="">-Select-</option>
                                {DIOCESES_LIST.map((item) => (
                                  <option value={item}>
                                    {upperCase(item)}
                                  </option>
                                ))}

                                {/*<option value="csi tvl">
                                  CSI Diocese Tirunelveli
                                </option>

                                <option value="other diocese">
                                  Other Diocese
                                </option>
                                <option value="rc"> Roman Catholic </option>
                                <option value="other"> Others </option>*/}
                              </Form.Control>
                              {upperCase(denomination) ==
                                upperCase(
                                  context.settingValue(
                                    "clg_admission_denomination"
                                  )
                                ) &&
                                context.settingValue(
                                  "ug_baptism_certificate"
                                ) == "1" && (
                                  <span className="text-danger mt-3">
                                    You must upload the Baptism Certificate at
                                    Step-II
                                  </span>
                                )}
                            </Col>
                          </Row>
                        )}

                      {lowerCase(selectedReligion) == "christian" &&
                        upperCase(selectedCommunity) == "BC" &&
                        context.settingValue("fiele_ug_dalit_required") ==
                          "1" && (
                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Dalit ?<span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                as="select"
                                name="is_dalit"
                                size="sm"
                                className="fw-bold form-select form-select-sm"
                                defaultValue={basicField("is_dalit")}
                                required
                              >
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                              </Form.Control>
                            </Col>
                          </Row>
                        )}

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Blood Group
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            as="select"
                            name="i__bloodGroup"
                            size="sm"
                            className="fw-bold form-select form-select-sm"
                            defaultValue={basicField("blood_group")}
                            required
                          >
                            <option value="">-Select-</option>
                            {BLOOD_GROUPS.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </Form.Control>
                          <input
                            type="hidden"
                            name="i__willingToDonateBlood"
                            value="0"
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Mother Tongue
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            as="select"
                            name="i__motherTongue"
                            size="sm"
                            className="fw-bold form-select form-select-sm"
                            defaultValue={basicField("mother_tongue")}
                            required
                          >
                            <option value="">-Select-</option>
                            {LANGUAGES.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>

                      <Row className="py-2">
                        <Col md={3}>
                          <label>
                            Nationality
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            as="select"
                            name="i__nationality"
                            size="sm"
                            className="fw-bold form-select form-select-sm"
                            defaultValue={basicField("nationality")}
                            required
                          >
                            <option value="">-Select-</option>
                            <option value="indian">Indian</option>
                            <option value="other">Other</option>
                          </Form.Control>
                        </Col>
                      </Row>

                      {/*context.settingValue(
                        "adm_ug_required_placement_input"
                      ) == "1" && (
                        <Row className="py-2 border-bottom">
                          <Col md={3}>
                            <label>
                              Placement & Training interest
                              <span className="text-danger">*</span>
                            </label>
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              as="select"
                              name="placement_training_interest"
                              size="sm"
                              className="fw-bold form-select form-select-sm"
                              defaultValue={basicField(
                                "placement_training_interest"
                              )}
                              required
                            >
                              <option value="">-Select-</option>
                              {PLACEMENT_INTERESET_LIST.map((item) => (
                                <option value={item}>{upperCase(item)}</option>
                              ))}
                            </Form.Control>
                          </Col>
                        </Row>
                      )*/}

                      <Row className="context_box_title mt-2">
                        <Col md={12}>
                          <h5>Family Details :</h5>
                        </Col>
                      </Row>

                      <input
                        type="hidden"
                        name="father_status"
                        value={fatherStatus ? "1" : "0"}
                      />

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Parental Status
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            as="select"
                            name="parental_status"
                            size="sm"
                            className="fw-bold text-uppercase form-select form-select-sm"
                            value={parentalStatus}
                            onChange={(e) => handleParentalStatusChange(e)}
                            required
                          >
                            <option value="">-Select-</option>
                            {PARENTAL_STATUS.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Father's Name
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="text"
                            name="i__fatherName"
                            size="sm"
                            className="fw-bold text-uppercase"
                            placeholder="Father's Name"
                            defaultValue={basicField("father_name")}
                            required
                          />
                        </Col>
                      </Row>

                      {fatherStatus != "0" && (
                        <>
                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Father's Occupation Type
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                as="select"
                                name="father_work_nature"
                                size="sm"
                                placeholder="Father's work details"
                                className="fw-bold "
                                defaultValue={basicField("father_work_nature")}
                                required
                              >
                                <option value="">-Select-</option>
                                {WORK_NATURE.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                              </Form.Control>
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Father's Occupation
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                type="text"
                                name="i__fatherOccupation"
                                size="sm"
                                placeholder="Father's work details"
                                className="fw-bold "
                                defaultValue={basicField("father_occupation")}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Father's Mobile Number
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                type="number"
                                name="father_phone"
                                size="sm"
                                className="fw-bold"
                                placeholder="Father's Mobile Number"
                                onWheel={(e) => e.target.blur()}
                                onKeyPress={(e) => integerIndMobile(e)}
                                min="0"
                                defaultValue={basicField("father_phone")}
                                required
                              />
                            </Col>
                          </Row>
                        </>
                      )}

                      <input
                        type="hidden"
                        name="mother_status"
                        value={motherStatus ? "1" : "0"}
                      />

                      {/*<Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Is your Mother alive?
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            as="select"
                            name="mother_status"
                            size="sm"
                            className="fw-bold text-uppercase form-select form-select-sm"
                            value={motherStatus}
                            onChange={(e) => setMotherStatus(e.target.value)}
                            required
                          >
                            <option value="">-Select-</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </Form.Control>
                        </Col>
                      </Row>*/}

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Mother's Name
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="text"
                            name="i__motherName"
                            size="sm"
                            className="fw-bold text-uppercase"
                            placeholder="Mother's Name"
                            defaultValue={basicField("mother_name")}
                            required
                          />
                        </Col>
                      </Row>

                      {motherStatus != "0" && (
                        <>
                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Mother's Occupation Type
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                as="select"
                                name="mother_work_nature"
                                size="sm"
                                placeholder="Mother's work details"
                                className="fw-bold "
                                defaultValue={basicField("mother_work_nature")}
                                required
                              >
                                <option value="">-Select-</option>
                                {WORK_NATURE.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                              </Form.Control>
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Mother's Occupation
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                type="text"
                                name="i__motherOccupation"
                                size="sm"
                                placeholder="Mother's work details"
                                className="fw-bold "
                                defaultValue={basicField("mother_occupation")}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Mother's Mobile Number
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                type="number"
                                name="mother_phone"
                                size="sm"
                                className="fw-bold"
                                placeholder="Mother's Mobile Number"
                                onWheel={(e) => e.target.blur()}
                                onKeyPress={(e) => integerIndMobile(e)}
                                min="0"
                                defaultValue={basicField("mother_phone")}
                                required
                              />
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Family Annual Income
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          {/*<Form.Control
                            type="number"
                            name="i__annualIncome"
                            placeholder="Annual Income"
                            size="sm"
                            className="fw-bold"
                            onKeyPress={decimalKeyPress}
                            onWheel={(e) => e.target.blur()}
                            defaultValue={basicField("annual_income")}
                            required
                    />*/}
                          <Form.Control
                            as="select"
                            name="annul_income_range"
                            size="sm"
                            className="fw-bold form-select form-select-sm"
                            defaultValue={basicField("annul_income_range")}
                            required
                          >
                            <option value="">-Select-</option>
                            {ANNUAL_INCOME_RANGE.map((item) => (
                              <option value={item}>{item}</option>
                            ))}
                          </Form.Control>
                        </Col>
                      </Row>

                      <Row className="py-2 ">
                        <Col md={3}>
                          <label>
                            Special Category
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            as="select"
                            name="urban_rural"
                            size="sm"
                            className="fw-bold form-select form-select-sm"
                            defaultValue={basicField("urban_rural")}
                            required
                          >
                            <option value="">-Select-</option>
                            <option value="urban">Urban</option>
                            <option value="semi-urban">Semi-Urban</option>
                            <option value="rural">Rural</option>
                          </Form.Control>
                        </Col>
                      </Row>

                      {fatherStatus == "0" && motherStatus == "0" && (
                        <>
                          <Row className="context_box_title mt-2">
                            <Col md={12}>
                              <h5>Guardian Details :</h5>
                            </Col>
                          </Row>
                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Guardian Name
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                type="text"
                                name="guardian_name"
                                size="sm"
                                className="fw-bold text-uppercase"
                                placeholder="Guardian Name"
                                defaultValue={basicField("guardian_name")}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Guardian Relationship
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                type="text"
                                name="guardian_relationship"
                                size="sm"
                                placeholder="Guardian Relationship"
                                className="fw-bold "
                                defaultValue={basicField(
                                  "guardian_relationship"
                                )}
                                required
                              />
                            </Col>
                          </Row>

                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Guardian Mobile Number
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                type="number"
                                name="guardian_phone"
                                size="sm"
                                className="fw-bold"
                                placeholder="Guardian Mobile Number"
                                onWheel={(e) => e.target.blur()}
                                onKeyPress={(e) => integerIndMobile(e)}
                                min="0"
                                defaultValue={basicField("guardian_phone")}
                                required
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row className="context_box_title mt-2">
                        <Col md={12}>
                          <h5>Special Reservation :</h5>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={9}>
                          <label>
                            Whether the applicant is First-Generation Applicant?
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Check
                            type="radio"
                            inline
                            name="first_graduate"
                            label="Yes"
                            value="1"
                            defaultChecked={
                              specialReservation.firstgraduate == 1
                            }
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                firstgraduate: 1,
                              })
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="first_graduate"
                            label="No"
                            value="0"
                            defaultChecked={
                              specialReservation.firstgraduate == 0
                            }
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                firstgraduate: 0,
                              })
                            }
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={9}>
                          <label>
                            Whether the applicant is differently abled ?
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Check
                            type="radio"
                            inline
                            name="i__isPhysicallyChallanged"
                            label="Yes"
                            value="1"
                            defaultChecked={specialReservation.physical == 1}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                physical: 1,
                              })
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__isPhysicallyChallanged"
                            label="No"
                            value="0"
                            defaultChecked={specialReservation.physical == 0}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                physical: 0,
                              })
                            }
                            required
                          />
                        </Col>
                        <Col md={12} className="text-end">
                          {specialReservation.physical == "1" &&
                            context.settingValue(
                              "admission_ug_cert_physical_require"
                            ) == "1" && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "13px" }}
                              >
                                You must upload the Certificate at Step-II.
                              </span>
                            )}
                        </Col>
                      </Row>

                      {specialReservation.physical == "1" && (
                        <>
                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Disability Code{" "}
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                as="select"
                                name="disability_code"
                                className="fw-bold form-select form-select-sm"
                                required
                              >
                                <option value="">-Select-</option>
                                {disabilityList.map((item) => (
                                  <option
                                    value={item.disability_code}
                                    selected={
                                      basicField("disability_code") ==
                                      item.disability_code
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {item.disability_code} -{" "}
                                    {item.disability_details}
                                  </option>
                                ))}
                              </Form.Control>
                            </Col>
                          </Row>
                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                Disability Percentage
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={9}>
                              <Form.Control
                                type="number"
                                name="disability_percentage"
                                className="fw-bold"
                                min="1"
                                max="100"
                                placeholder="Disability Percentage"
                                onWheel={(e) => e.target.blur()}
                                defaultValue={basicField(
                                  "disability_percentage"
                                )}
                                required
                              />
                            </Col>
                          </Row>
                          <Row className="py-2 border-bottom">
                            <Col md={3}>
                              <label>
                                District Name
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={3}>
                              <Form.Control
                                type="text"
                                size="sm"
                                name="disablity_district"
                                className="fw-bold text-uppercase"
                                placeholder="District Name"
                                defaultValue={basicField("disablity_district")}
                                required
                              />
                            </Col>
                            <Col md={3}>
                              <label>
                                Identity Card No
                                <span className="text-danger">*</span>
                              </label>
                            </Col>
                            <Col md={3}>
                              <Form.Control
                                type="text"
                                size="sm"
                                name="disability_card_no"
                                className="fw-bold text-uppercase"
                                placeholder="ID Card No"
                                defaultValue={basicField("disability_card_no")}
                                required
                              />
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row className="py-2 border-bottom">
                        <Col md={9}>
                          <label>
                            Whether the applicant is child of Ex-Serviceman ?
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Check
                            type="radio"
                            inline
                            name="i__exArmyChild"
                            label="Yes"
                            value="1"
                            defaultChecked={specialReservation.exservice == 1}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                exservice: 1,
                              })
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__exArmyChild"
                            label="No"
                            value="0"
                            defaultChecked={specialReservation.exservice == "0"}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                exservice: 0,
                              })
                            }
                            required
                          />
                        </Col>
                        <Col md={12} className="text-end">
                          {specialReservation.exservice == "1" &&
                            context.settingValue(
                              "admission_ug_cert_exservice_require"
                            ) == "1" && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "13px" }}
                              >
                                You must upload the Certificate at Step-II.
                              </span>
                            )}
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={9}>
                          <label>
                            Whether the applicant is a Sports Person ?
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Check
                            type="radio"
                            inline
                            name="i__inSports"
                            label="Yes"
                            value="1"
                            defaultChecked={specialReservation.inSports == 1}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                inSports: 1,
                              })
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__inSports"
                            label="No"
                            value="0"
                            defaultChecked={specialReservation.inSports == 0}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                inSports: 0,
                              })
                            }
                            required
                          />
                        </Col>
                        <Col md={12} className="text-end">
                          {specialReservation.inSports == "1" &&
                            context.settingValue(
                              "admission_ug_cert_sports_require"
                            ) == "1" && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "13px" }}
                              >
                                You must upload the Certificate at Step-II.
                              </span>
                            )}
                        </Col>
                      </Row>

                      {specialReservation.inSports == "1" && (
                        <Row className="py-2 border-bottom">
                          <Col md={3}>
                            <label>
                              Level in sports
                              <span className="text-danger">*</span>
                            </label>
                          </Col>
                          <Col md={9}>
                            <Form.Control
                              as="select"
                              name="i__sportsLevel"
                              size="sm"
                              className="fw-bold form-select form-select-sm"
                              defaultValue={basicField("sports_level")}
                              required
                            >
                              <option value="">-Select-</option>
                              {SPORTS_LEVEL.map((item) => (
                                <option value={item}>{item}</option>
                              ))}
                            </Form.Control>
                          </Col>
                        </Row>
                      )}

                      <Row className="py-2 border-bottom">
                        <Col md={9}>
                          <label>
                            Whether the applicant is migrated from Jammu and
                            Kashmir ?<span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Check
                            type="radio"
                            inline
                            name="i__kashmire"
                            label="Yes"
                            value="1"
                            defaultChecked={specialReservation.kashmire == 1}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                kashmire: 1,
                              })
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__kashmire"
                            label="No"
                            value="0"
                            defaultChecked={specialReservation.kashmire == 0}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                kashmire: 0,
                              })
                            }
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={9}>
                          <label>
                            Whether the applicant is Tamil Origin from Andaman
                            Nicobar islands ?
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Check
                            type="radio"
                            inline
                            name="i__sriLankan"
                            label="Yes"
                            value="1"
                            defaultChecked={specialReservation.sriLankan == 1}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                sriLankan: 1,
                              })
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__sriLankan"
                            label="No"
                            value="0"
                            defaultChecked={specialReservation.sriLankan == 0}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                sriLankan: 0,
                              })
                            }
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={9}>
                          <label>
                            Whether the applicant is belongs to Other State or
                            NRI ?<span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Check
                            type="radio"
                            inline
                            name="i__nri"
                            label="Yes"
                            value="1"
                            defaultChecked={specialReservation.nri == 1}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                nri: 1,
                              })
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__nri"
                            label="No"
                            value="0"
                            defaultChecked={specialReservation.nri == 0}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                nri: 0,
                              })
                            }
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={9}>
                          <label>
                            {/*Participation in NCC ?*/}
                            Whether the applicant is a Registered NCC Cadet ?
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Check
                            type="radio"
                            inline
                            name="i__inNcc"
                            label="Yes"
                            value="1"
                            defaultChecked={specialReservation.inNcc == 1}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                inNcc: 1,
                              })
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__inNcc"
                            label="No"
                            value="0"
                            defaultChecked={specialReservation.inNcc == 0}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                inNcc: 0,
                              })
                            }
                            required
                          />
                        </Col>
                        <Col md={12} className="text-end">
                          {specialReservation.inNcc == "1" &&
                            context.settingValue(
                              "admission_ug_cert_ncc_require"
                            ) == "1" && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "13px" }}
                              >
                                You must upload the Certificate at Step-II.
                              </span>
                            )}
                        </Col>
                      </Row>

                      <Row className="py-2  border-bottom">
                        <Col md={9}>
                          <label>
                            {/*Participation in NSS ?*/}
                            Whether the applicant is a Registered NSS Volunteer
                            ?<span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Check
                            type="radio"
                            inline
                            name="i__inNss"
                            label="Yes"
                            value="1"
                            defaultChecked={specialReservation.inNss == 1}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                inNss: 1,
                              })
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="i__inNss"
                            label="No"
                            value="0"
                            defaultChecked={specialReservation.inNss == 0}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                inNss: 0,
                              })
                            }
                            required
                          />
                        </Col>
                        <Col md={12} className="text-end">
                          {specialReservation.inNss == "1" &&
                            context.settingValue(
                              "admission_ug_cert_nss_require"
                            ) == "1" && (
                              <span
                                className="text-danger"
                                style={{ fontSize: "13px" }}
                              >
                                You must upload the Certificate at Step-II.
                              </span>
                            )}
                        </Col>
                      </Row>

                      <Row className="py-2">
                        <Col md={9}>
                          <label>
                            Whether the applicant requires Hostel accommodation?
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={3}>
                          <Form.Check
                            type="radio"
                            inline
                            name="need_hostel"
                            label="Yes"
                            value="1"
                            defaultChecked={specialReservation.hostel == 1}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                hostel: 1,
                              })
                            }
                            required
                          />
                          <Form.Check
                            type="radio"
                            inline
                            name="need_hostel"
                            label="No"
                            value="0"
                            defaultChecked={specialReservation.hostel == 0}
                            onChange={(e) =>
                              setSpecialReservation({
                                ...specialReservation,
                                hostel: 0,
                              })
                            }
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="context_box_title mt-2">
                        <Col md={12}>
                          <h5>Address for Communication :</h5>
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Door No / Street
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="text"
                            name="i__street"
                            size="sm"
                            className="fw-bold text-uppercase"
                            placeholder="Door No / Street"
                            defaultValue={basicField("street")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Place / Area
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="text"
                            name="i__place"
                            size="sm"
                            className="fw-bold text-uppercase"
                            placeholder="Place"
                            defaultValue={basicField("place")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Village / City
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="text"
                            name="i__city"
                            size="sm"
                            className="fw-bold text-uppercase"
                            placeholder="Village / City"
                            defaultValue={basicField("city")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Taluk
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="text"
                            name="taluk"
                            size="sm"
                            className="fw-bold text-uppercase"
                            placeholder="Taluk"
                            defaultValue={basicField("taluk")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            District
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="text"
                            name="i__district"
                            size="sm"
                            className="fw-bold text-uppercase"
                            placeholder="District"
                            defaultValue={basicField("district")}
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Country
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <CountryDropdown
                            className="form-select form-select-sm fw-bold text-uppercase"
                            name="i__country"
                            value={selectedCountry}
                            onChange={(val) => setSelectedCountry(val)}
                            required="required"
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            State
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <RegionDropdown
                            className="form-select form-select-sm fw-bold text-uppercase"
                            name="i__state"
                            country={selectedCountry}
                            value={selectedState}
                            onChange={(val) => setSelectedState(val)}
                            required="required"
                          />
                        </Col>
                      </Row>

                      <Row className="py-2 border-bottom">
                        <Col md={3}>
                          <label>
                            Pincode
                            <span className="text-danger">*</span>
                          </label>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="number"
                            name="i__pinCode"
                            size="sm"
                            className="fw-bold"
                            placeholder="Pincode"
                            inputMode="numeric"
                            onWheel={(e) => e.target.blur()}
                            onKeyPress={(e) => integerIndPincode(e)}
                            defaultValue={basicField("pincode")}
                            min="1"
                            required
                          />
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col md={3}></Col>
                        <Col md={4} xs={12} sm={12}>
                          <img src={captcha && captcha.img} />
                          <Button
                            type="button"
                            className="ms-2"
                            variant="white border"
                            size="sm"
                            onClick={(e) => loadCaptcha()}
                          >
                            <i className="fa-solid fa-arrow-rotate-right"></i>
                          </Button>
                        </Col>
                        <Col md={5} xs={12} sm={12}>
                          <Form.Control
                            type="text"
                            name="i__captcha"
                            required
                            className="fw-bold"
                            placeholder="Enter captcha code"
                          />
                        </Col>
                      </Row>

                      <input
                        type="hidden"
                        name="i__captchaCode"
                        value={captcha.code}
                      />
                      <Row className="my-4">
                        {props.pageType && props.pageType == "edit" && (
                          <Col md={6}>
                            <Link to="/" className="btn btn-danger w-100">
                              Cancel
                            </Link>
                          </Col>
                        )}
                        <Col
                          md={
                            props.pageType && props.pageType == "edit" ? 6 : 12
                          }
                        >
                          <Button
                            type="submit"
                            variant="success"
                            className="w-100"
                            disabled={loader}
                          >
                            Save & Continue
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Spin>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </div>

      <ConfirmModal
        title="Confirm"
        content={
          <div>
            Please check all the filled informations before processing. Do you
            want to proceed next ?
          </div>
        }
        confirmText="Save & Proceed"
        show={showConfirm}
        onHide={(e) => setShowConfirm(false)}
        onCancel={(e) => setShowConfirm(false)}
        onConfirm={(e) => submitForm()}
      />
    </>
  );
};

export default StepOne;
