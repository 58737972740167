import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import EditPersonal from "./editPersonal";
import EditMarks from "./editMarks";
import EditCertificates from "./editCertificates";

const PgEdit = (props) => {
  const context = useContext(PsContext);
  const [type, setType] = useState(null);

  useEffect(() => {
    setType(props.match.params.type);
  }, [props.match.params]);

  if (type == "personal") return <EditPersonal {...props} />;
  if (type == "marks") return <EditMarks {...props} />;
  if (type == "certificates") return <EditCertificates {...props} />;

  return <></>;
};

export default PgEdit;
