import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField from "./inputField";
import PsContext from "../../../context";
import { upperCase } from "../../../utils";

const FamilyDetails = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    if (props.data && props.data[fieldName]) return props.data[fieldName];
  };

  return (
    <>
      <InputField
        label="Father's Name"
        value={upperCase(field("father_name"))}
      />

      <InputField
        label="Father's Occupation"
        value={upperCase(field("father_occupation"))}
      />

      <InputField
        label="Father's Phone"
        value={upperCase(field("father_phone"))}
      />

      <InputField
        label="Mother's Name"
        value={upperCase(field("mother_name"))}
      />

      <InputField
        label="Mother's Occupation"
        value={upperCase(field("mother_occupation"))}
      />

      <InputField
        label="Family Annual Income"
        value={upperCase(field("annual_income"))}
      />
    </>
  );
};

export default FamilyDetails;
