import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { baseUrl } from "../../../utils";

const PaymentStatus = (props) => {
  const context = useContext(PsContext);
  const [uuid, setUuid] = useState(null);

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setUuid(props.match.params.uuid);
  }, [props.match.uuid]);

  useEffect(() => {
    if (uuid && uuid.length > 5) loadData();
  }, [uuid]);

  const loadData = () => {
    context.setLoader(true);
    const form = new FormData();
    form.append("i__apiCode", context.user.api_code);
    form.append("i__orderId", uuid);
    axios.post(ServiceUrl.UG.APPLIED_ORDER_DETAILS, form).then((res) => {
      if (res["data"].status == "1") {
        setDataList(res["data"].data);
      } else {
        toast.error(res["data"].message || "Error");
      }
      context.setLoader(false);
    });
  };

  const getField = (field) => {
    try {
      var d = dataList;
      return d[0][field];
    } catch (er) {}
  };

  const downloadFile = (item) => {
    context.setLoader(true);
    var filePath =
      baseUrl +
      ServiceUrl.UG.APPLICATION_DOWNLOAD_LINK +
      item.admission_year +
      "/" +
      item.uuid +
      "/" +
      item.application_code;

    var a = document.createElement("A");
    a.href = filePath;
    a.download = filePath.substr(filePath.lastIndexOf("/") + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    context.setLoader(false);
  };

  return (
    <>
      <div className="container py-3">
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <Card>
              <Card.Header>Payment Status</Card.Header>
              <Card.Body>
                {getField("fee_paid_status") == "0" && (
                  <div>
                    <Alert variant="danger">
                      <h4>We are getting error in process the payment</h4>
                      <hr />
                      <p>Payment Status : {getField("fee_paid_response")}</p>
                    </Alert>
                  </div>
                )}
                {getField("fee_paid_status") == "1" && (
                  <div>
                    <Alert variant="success">
                      <h4>Your payment has been completed.</h4>
                      <hr />
                      <p>Payment Status : {getField("fee_paid_response")}</p>
                    </Alert>
                    <div className="text-center">
                      <Button
                        type="buttton"
                        size="sm"
                        variant="success"
                        onClick={(e) => downloadFile(dataList[0])}
                      >
                        <i className="fa-solid fa-download me-2"></i>
                        Download Application
                      </Button>
                    </div>
                  </div>
                )}
                <Row className="my-4">
                  <Col md={12} className="text-center">
                    <Link to="/" className="btn btn-primary btn-sm">
                      <i className="fa-solid fa-house me-2"></i>Go to dashboard
                    </Link>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PaymentStatus;
