import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import { withRouter } from "react-router-dom";

import PsContext from "../../../context";
import { IMAGES } from "../../../utils/data";
import { Col, Row } from "react-bootstrap";
import SignUpLink from "../../signUp/signUpLink";
import LoginForm from "../../login/loginForm";
import Announcement from "./announcement";
import HelpLineInfo from "./helpLineInfo";

const HomePage = (props) => {
  const context = useContext(PsContext);

  return (
    <>
      <div className="container">
        <Row>
          {/*<Col md={7} className="d-none d-sm-block">
            <Announcement />
          </Col>
          <Col md={5}>
            <LoginForm />
          </Col>
          <Col md={7} className="d-sm-block d-md-none mt-3">
            <Announcement />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <HelpLineInfo />
          </Col>*/}
          <Col md={{ offset: 3, span: 6 }}>
            <LoginForm />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withRouter(HomePage);
