import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const InputField3 = (props) => {
  return (
    <>
      <Row className="mt-2">
        <Col md={6}>
          <label>{props.label}</label>
        </Col>
        <Col md={3} sm={6} xs={6}>
          <Form.Control
            size="sm"
            className="fw-bold"
            readOnly
            value={
              props.value && props.value.length > 0
                ? "UPLOADED"
                : "NOT UPLOADED"
            }
          />
        </Col>
        <Col md={3} sm={6} xs={6} className="text-center">
          {props.value && props.value.length > 0 && (
            <a
              href={props.fileLink}
              target="_blank"
              className="btn btn-sm btn-primary"
            >
              VIEW
              <i className="fa-solid fa-arrow-up-right-from-square ms-3"></i>
            </a>
          )}
        </Col>
      </Row>
    </>
  );
};

export default InputField3;
