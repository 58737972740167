import { Button, Dialog, List } from "antd-mobile";
import React, { useState, useContext, useEffect } from "react";

import { withRouter } from "react-router-dom";

import PsContext from "../../context";
import WebLayout from "./webLayout";
import { upperCase } from "../../utils";
import UgAdmissionLayout from "../ugAdmission/ugAdmissionLayout";
import PgAdmissionLayout from "../pgAdmission/pgAdmissionLayout";
import MphilAdmissionLayout from "../mphilAdmission/mphilAdmissionLayout";

const Web = (props) => {
  const context = useContext(PsContext);

  useEffect(() => {
    context.loadSettings();
  }, []);

  const themeStyles = `
  .header-top, footer, .bg-theme{
    background-color:${context.settingValue("theme_dark_color")} !important;    
  }
  .side-menu > li a.active {
    background-color: ${context.settingValue(
      "theme_sidebar_bgcolor"
    )} !important;
  }
`;

  if (context.logged == "yes") {
    if (upperCase(context.user.applied_for) == "UG") {
      return (
        <section className="">
          <style>{themeStyles}</style>
          <UgAdmissionLayout {...props} />
        </section>
      );
    } else if (upperCase(context.user.applied_for) == "PG") {
      return (
        <section className="">
          <style>{themeStyles}</style>
          <PgAdmissionLayout {...props} />
        </section>
      );
    } else if (upperCase(context.user.applied_for) == "MPHIL") {
      return (
        <section className="">
          <style>{themeStyles}</style>
          <MphilAdmissionLayout {...props} />
        </section>
      );
    } else return <section className=""></section>;
  }

  return (
    <>
      <style>{themeStyles}</style>
      <section className="">
        <WebLayout {...props} />
      </section>
    </>
  );
};

export default withRouter(Web);
