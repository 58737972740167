import React, { useContext } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import PsContext from "../../context";

const StepMenu = (props) => {
  const context = useContext(PsContext);

  const title = (step) => {
    let editText = props.pageType && props.pageType == "edit" ? "Edit " : "";
    if (step == 1) {
      return (
        <>
          {context.user.application_declaration == "0" &&
            context.user.registration_completed == "1" && (
              <Link to="/edit/personal" title="Edit Step-I">
                <i className="fa-solid fa-pen-to-square me-2"></i>
              </Link>
            )}
          {props.activeKey == "step1" && editText} Step-I
        </>
      );
    } else if (step == 2) {
      return (
        <>
          {context.user.application_declaration == "0" &&
            context.user.certificates_uploaded == "1" && (
              <Link to="/edit/certificates" title="Edit Step-II">
                <i className="fa-solid fa-pen-to-square me-2"></i>
              </Link>
            )}
          {props.activeKey == "step2" && editText} Step-II
        </>
      );
    } else if (step == 3) {
      return (
        <>
          {context.user.application_declaration == "0" &&
            context.user.marks_uploaded == "1" && (
              <Link to="/edit/marks" title="Edit Step-III">
                <i className="fa-solid fa-pen-to-square me-2"></i>
              </Link>
            )}
          {props.activeKey == "step3" && editText} Step-III
        </>
      );
    }
  };

  return (
    <>
      <Tabs activeKey={props.activeKey || ""} justify>
        <Tab eventKey="step1" title={title(1)} />
        <Tab eventKey="step2" title={title(2)} />
        <Tab eventKey="step3" title={title(3)} />
        <Tab
          eventKey="step4"
          title={
            props.pageType &&
            props.pageType == "edit" &&
            props.activeKey == "step4"
              ? "Edit Step-IV"
              : "Step-IV"
          }
        />
      </Tabs>
    </>
  );
};
export default StepMenu;
