import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

import { toast } from "react-hot-toast";
import PsContext from "../../../../context";
import axios from "axios";
import { ServiceUrl } from "../../../../utils/serviceUrl";
import { baseUrl } from "../../../../utils";

const ProfilePhotoUpload = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [applicant, setApplicant] = useState(props.applicant);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const [newPhoto, setNewPhoto] = useState(false);

  const fileReaderChange = (e) => {
    try {
      let file = e.target.files[0];
      let totalsize = file.size;
      /*let totalsize = file.size;
    var totalSizeKB = totalsize / Math.pow(1024,1)
    var totalSizeMB = totalsize / Math.pow(1024,2)
    var totalSizeGB = totalsize / Math.pow(1024,3)
    */

      let mz = props.minSize / Math.pow(1000, 1);
      let mx = props.maxSize / Math.pow(1000, 1);
      /*
    if (!types.includes(file.fileList[0].type)) {
      toast.error("Please select an Image with file extension .jpg or .png");
      return;
    }
*/

      if (totalsize < props.minSize) {
        toast.error("File size must greater than " + mz + " kb");
        return;
      }

      if (totalsize > props.maxSize) {
        toast.error("File size must less than " + mx + " kb");
        return;
      }
      setSelectedFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        setImagePreview(readerEvent.target.result);
      };
    } catch (er) {
      console.log(er);
    }
  };

  const getFile = () => {
    return imagePreview
      ? imagePreview
      : props.filePath
      ? baseUrl +
        ServiceUrl.PG.VIEW_CERTIFICATE +
        "?api_code=" +
        context.user.api_code +
        "&field_name=" +
        props.fileName
      : "";
  };

  const uploadFiles = () => {
    setLoader(true);
    context.setLoader(true);
    const form = new FormData();
    form.append("i__apiCode", context.user.api_code);
    form.append("i__userId", context.user.user_id);
    form.append("i__userCode", context.user.user_code);
    form.append("i__fileName", props.fileName);
    form.append("i___file", selectedFile);
    axios.post(ServiceUrl.PG.UPLOAD_FILE, form).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");
        setNewPhoto(false);
        setSelectedFile(null);
        setImagePreview(null);
        context.setLoader(false);
        if (props.onSuccess) props.onSuccess();
      } else {
        toast.error(res["data"].message || "Error");
        context.setLoader(false);
      }
      context.setLoader(false);
    });
  };

  return (
    <div className="">
      <Form
        action=""
        method="post"
        encType="mutlipart/form-data"
        id="frm_PhotoUpload"
      >
        <Row className="border-bottom py-3 ">
          <Col md={8}>
            <b>{props.title}</b>
            <div className="upload_info mt-2">{props.content}</div>
          </Col>
          <Col md={4}>
            <center>
              <img
                src={getFile()}
                style={{ width: "80px", cursor: "pointer" }}
              />
              <br />
              {imagePreview && (
                <>
                  <Button
                    type="button"
                    size="sm"
                    variant="success"
                    className="mt-2"
                    onClick={(e) => uploadFiles()}
                  >
                    Upload Image
                  </Button>
                </>
              )}

              {!imagePreview && (
                <>
                  <input
                    type="file"
                    name="file"
                    id={props.fileName}
                    className="inputfile"
                    onChange={(e) => fileReaderChange(e)}
                    accept=".png,.jpg,.jpeg"
                  />
                  <label for={props.fileName}>Choose a file</label>
                </>
              )}
            </center>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default ProfilePhotoUpload;
