import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import { Tab, Tabs } from "react-bootstrap";

const TabMenu = (props) => {
  const context = useContext(PsContext);

  const [selectedTab, setSelectedTab] = useState(props.tabKey);

  useEffect(() => {
    setSelectedTab(props.tabkey);
  }, [props.tabKey]);

  const handleTabChange = (key) => {
    setSelectedTab(key);
    if (props.onTabChange) props.onTabChange(key);
  };

  return (
    <div className="mt-2">
      <Tabs onSelect={handleTabChange} activeKey={selectedTab} fill>
        {context.settingValue("admission_has_pg_regular") == "1" && (
          <Tab eventKey={"regular"} title="Apply for Aided (Regular) Course" />
        )}
        {context.settingValue("admission_has_pg_self") == "1" && (
          <Tab eventKey={"self"} title="Apply for Un-Aided (Self) Course" />
        )}
      </Tabs>
    </div>
  );
};

export default TabMenu;
