import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import PsContext from "../../../context";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Spin } from "antd";
import Personal from "./personal";
import FamilyDetails from "./familyDetails";
import SpecialReservation from "./specialReservation";
import CommunicationDetails from "./communicationDetails";
import QualificationDetails from "./qualificationDetails";
import CertificateDetails from "./certificateDetails";
import ConfirmModal from "../../confirmModal";
import { baseUrl } from "../../../utils";

const ApplicationPreview = (props) => {
  const context = useContext(PsContext);

  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [applicant, setApplicant] = useState([]);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);

  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    loadApplicantInfo();
    loadData();
  }, []);

  const loadData = () => {
    setLoader(true);
    const form = new FormData();
    form.append("api_code", context.user.api_code);
    axios.post(ServiceUrl.PG.PERSONAL_MARKS_PREVIEW, form).then((res) => {
      if (res["data"].status == "1") {
        setBaisc(res["data"].data.basic);
        setMarks(res["data"].data.marks);
      } else {
        toast.error(res["data"].message || "Error");
      }
      setLoader(false);
    });
  };

  const loadApplicantInfo = () => {
    try {
      setLoader(true);
      setApplicant([]);
      var form = new FormData();
      form.append("i__apiCode", context.api);
      axios.post(ServiceUrl.PG.APPLICANT_MIN_INFO, form).then((res) => {
        if (res["data"].status == "1") {
          let d = res["data"].data;

          setApplicant(d);
          setLoader(false);
        } else {
          toast.error(res["data"].message || "error");
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropgation();
      setValidated(true);
      return;
    }

    setShowConfirm(true);
  };

  const submitForm = () => {
    setLoader(true);
    setShowConfirm(false);
    const form = new FormData();
    form.append("i__apiCode", context.user.api_code);
    axios.post(ServiceUrl.UG.UPDATE_DECLARATION, form).then((res) => {
      if (res["data"].status == "1") {
        toast.success(res["data"].message || "Success");
        context.updateUser(res["data"].user);
        setLoader(false);
        props.history.push("/apply");
      } else {
        toast.error(res["data"].message || "Error");
        context.setLoader(false);
        setLoader(false);
      }
    });
  };

  return (
    <>
      <div className="container mb-3">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Spin spinning={loader}>
              <Row>
                <Col md={12}>
                  <div className="text-center mb-4">
                    <h5>Preivew of Personal information and Marks details</h5>
                  </div>
                </Col>
              </Row>
              <Card>
                <Card.Header>
                  <div className="float-start">
                    Name :{"  "}
                    <b className="text-uppercase">
                      {applicant.name} {applicant.initial}
                    </b>
                  </div>
                  <div className="float-end">
                    Registration No : {"  "}
                    <b>{applicant.user_code}</b>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Row className="context_box_title mb-3">
                    <Col md={12}>
                      <h5>
                        Personal Details
                        <Link
                          to={`/edit/personal`}
                          className="text-dark float-end"
                        >
                          <i className="fa fa-edit me-2"></i>Edit
                        </Link>
                      </h5>
                    </Col>
                  </Row>

                  <Personal data={basic} />

                  <Row className="context_box_title mb-3 mt-3">
                    <Col md={12}>
                      <h5>Family Details </h5>
                    </Col>
                  </Row>

                  <FamilyDetails data={basic} />

                  <Row className="context_box_title mb-3 mt-3">
                    <Col md={12}>
                      <h5>Special Reservation </h5>
                    </Col>
                  </Row>

                  <SpecialReservation data={basic} />

                  <Row className="context_box_title mb-3 mt-3">
                    <Col md={12}>
                      <h5>Communication Details </h5>
                    </Col>
                  </Row>

                  <CommunicationDetails data={basic} />

                  <Row className="context_box_title mb-3 mt-3">
                    <Col md={12}>
                      <h5>
                        Details of Qualification
                        <Link
                          to={`/edit/marks`}
                          className="text-dark float-end"
                        >
                          <i className="fa fa-edit me-2"></i>Edit
                        </Link>
                      </h5>
                    </Col>
                  </Row>

                  <QualificationDetails data={marks} personal={basic} />

                  <Row className="context_box_title mb-3 mt-3">
                    <Col md={12}>
                      <h5>
                        Certificates
                        <Link
                          to={`/edit/certificates`}
                          className="text-dark float-end"
                        >
                          <i className="fa fa-edit me-2"></i>Edit
                        </Link>
                      </h5>
                    </Col>
                  </Row>

                  <CertificateDetails data={basic} />
                  <Form method="post" onSubmit={handleFormSubmit}>
                    <Row className="mt-3">
                      <Col md={11} className="text-end">
                        Signature <br />
                        <img
                          src={
                            baseUrl +
                            ServiceUrl.PG.VIEW_CERTIFICATE +
                            "?api_code=" +
                            context.user.api_code +
                            "&field_name=signature"
                          }
                          style={{
                            maxHeight: "50px",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md={12}>
                        <label>
                          <input type="checkbox" required />
                          &emsp;I hereby declare that the information given by
                          me above is true to the best of my knowledge.
                        </label>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col md={12} className="text-center">
                        <Button
                          type="submit"
                          className="w-100"
                          variant="success"
                          size="sm"
                        >
                          Save & Continue
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Spin>
          </Col>
        </Row>
      </div>

      <ConfirmModal
        title="Confirm"
        content={
          <div className="text-center">
            Please check all the filled informations before processing.
            <br />
            <span className="fw-bold text-danger">
              Hereafter, you cannot edit the filled informations.
            </span>
            <br />
            Do you want to proceed next ?
          </div>
        }
        confirmText="Save & Proceed"
        show={showConfirm}
        onHide={(e) => setShowConfirm(false)}
        onCancel={(e) => setShowConfirm(false)}
        onConfirm={(e) => submitForm()}
      />
    </>
  );
};

export default ApplicationPreview;
