import React, { useState, useContext, useEffect } from "react";

import { Link, withRouter } from "react-router-dom";

import PsContext from "../../context";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import { ACADEMIC_YEAR } from "../../utils";
import SignUpLink from "./signUpLink";

const SignUp = (props) => {
  const context = useContext(PsContext);

  return (
    <>
      <div className="container mt-4 mb-5">
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <SignUpLink />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withRouter(SignUp);
