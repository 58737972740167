import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmModal = (props) => {
  const confirmClick = () => {
    if (props.onConfirm) props.onConfirm();
  };

  const cancelClick = () => {
    if (props.onCancel) props.onCancel();
  };

  return (
    <>
      <Modal {...props}>
        <Modal.Header closeButton>{props.title}</Modal.Header>
        <Modal.Body>
          <div className="">{props.content}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            variant="danger"
            size="sm"
            onClick={(e) => cancelClick()}
          >
            <i className="fa-solid fa-xmark me-2"></i>{" "}
            {props.cancelText || "Cancel"}
          </Button>

          <Button
            type="button"
            variant="success"
            size="sm"
            onClick={(e) => confirmClick()}
          >
            <i className="fa-solid fa-check me-2"></i>{" "}
            {props.confirmText || "Yes"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ConfirmModal;
