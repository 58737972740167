import { Spin } from "antd";
import $ from "jquery";
import React, { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { useHistory, withRouter } from "react-router-dom";
import Pscontext from "../../../context";
import API from "../../../utils/api";

const RePrint = (props) => {
  const context = useContext(Pscontext);
  const history = useHistory();

  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    setLoader(true);
    API.post("v1/admin/checkadmission", $("#frm_admission").serialize())
      .then((res) => {
        if (res["data"].status === "1") {
          setDataSource(res["data"].data);
          props.onSuccess(res["data"].data);
        } else {
          toast.error(res["data"].message || "Error");
        }
        setLoader(false);
      })
      .catch((er) => {});
  };

  return (
    <>
      <Spin spinning={loader}>
        <Form
          method="post"
          noValidate
          validated={validated}
          id="frm_admission"
          onSubmit={handleFormSubmit}
        >
          <Row className="mt-1">
            <Col md={12}>
              <h6>Please Enter Your Enquiry No and Date of Birth</h6>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={12}>
              <label className="fs-sm">
                Enquiry No <span className="text-danger">*</span>
              </label>
              <Form.Control
                type="text"
                name="admission_no"
                className="fw-bold text-uppercase"
                size="sm"
                required
              />
            </Col>
            <span>E:g 2024A001</span>
          </Row>

          <Row className="mt-2">
            <Col md={12}>
              <label className="fs-sm">
                Date of Birth <span className="text-danger">*</span>
              </label>
              <Form.Control
                type="date"
                name="dob"
                className="fw-bold"
                size="sm"
                required
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Button type="submit" variant="primary" className="btn-block">
                LOG IN
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};
export default withRouter(RePrint);
