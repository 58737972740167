import { Button, Dialog, List } from "antd-mobile";
import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import axios from "axios";
import { withRouter } from "react-router-dom";

import PsContext from "../../../context";
import { Card, Col, Row } from "react-bootstrap";
import { ServiceUrl } from "../../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Spin } from "antd";

const WebPages = (props) => {
  const context = useContext(PsContext);

  const [loader, setLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    loadPage();
    //$("html, body").animate({ scrollTop: 0 }, "slow");
  }, [props.match.params]);

  const loadPage = () => {
    setLoader(true);
    axios
      .get(
        ServiceUrl.ADMISSION.CUSTOM_PAGE + "?slug=" + props.match.params.pageId
      )
      .then((res) => {
        if (res["data"].status == "1") {
          setDataList(res["data"].data);
        } else {
          toast.error(res["data"].message || "Error");
        }
        setLoader(false);
      });
  };

  return (
    <>
      <div className="container">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <Spin spinning={loader}>
              <div style={{ minHeight: "250px" }}>
                {dataList && dataList.id && (
                  <Card>
                    <Card.Header className="fw-bold fs-6">
                      {dataList.title}
                    </Card.Header>
                    <Card.Body>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: dataList.content_html,
                        }}
                      />
                    </Card.Body>
                  </Card>
                )}
              </div>
            </Spin>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withRouter(WebPages);
