import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import InputField3 from "./inputField3";
import PsContext from "../../../context";
import { DENOMINATION, baseUrl, upperCase } from "../../../utils";
import { ServiceUrl } from "../../../utils/serviceUrl";

const CertificateDetails = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);

  const [basic, setBaisc] = useState([]);
  const [marks, setMarks] = useState([]);
  const [preference, setPreference] = useState([]);

  const field = (fieldName) => {
    if (props.data && props.data[fieldName]) return props.data[fieldName];
  };

  const fileUrl = (fieldName) => {
    return (
      baseUrl +
      ServiceUrl.UG.VIEW_CERTIFICATE +
      "?api_code=" +
      field("api_code") +
      "&field_name=" +
      fieldName
    );
  };

  return (
    <>
      <InputField3
        label="Photo"
        value={field("profile_photo")}
        fileLink={fileUrl("profile_photo")}
      />

      <InputField3
        label="Signature"
        value={field("signature")}
        fileLink={fileUrl("signature")}
      />

      {upperCase(field("community")) != "OC" &&
        context.settingValue("admission_pg_cert_commu_require") == "1" && (
          <InputField3
            label="Community Certificate"
            value={field("community_certificate_path")}
            fileLink={fileUrl("community_certificate_path")}
          />
        )}

      {context.settingValue("admission_pg_cert_marksheet_require") == "1" && (
        <InputField3
          label="Marksheet"
          value={field("mark_sheet_path")}
          fileLink={fileUrl("mark_sheet_path")}
        />
      )}

      {context.settingValue("admission_pg_cert_tc_require") == "1" && (
        <InputField3
          label="Transfer Certificate"
          value={field("transfer_certificate_path")}
          fileLink={fileUrl("transfer_certificate_path")}
        />
      )}

      {field("physically_challanged") == "1" &&
        context.settingValue("admission_pg_cert_physical_require") == "1" && (
          <InputField3
            label="Physical Certificate"
            value={field("physical_certificate_path")}
            fileLink={fileUrl("physical_certificate_path")}
          />
        )}

      {field("ex_army_child") == "1" &&
        context.settingValue("admission_pg_cert_exservice_require") == "1" && (
          <InputField3
            label="Ex-Service man certificate"
            value={field("exmilitry_certificate_path")}
            fileLink={fileUrl("exmilitry_certificate_path")}
          />
        )}

      {field("in_sports") == "1" &&
        context.settingValue("admission_pg_cert_sports_require") == "1" && (
          <InputField3
            label="Sports certificate"
            value={field("sports_certificate_path")}
            fileLink={fileUrl("sports_certificate_path")}
          />
        )}

      {field("in_nss") == "1" &&
        context.settingValue("admission_pg_cert_nss_require") == "1" && (
          <InputField3
            label="NSS certificate"
            value={field("nss_certificate_path")}
            fileLink={fileUrl("nss_certificate_path")}
          />
        )}

      {field("in_ncc") == "1" &&
        context.settingValue("admission_pg_cert_ncc_require") == "1" && (
          <InputField3
            label="NCC certificate"
            value={field("ncc_certificate_path")}
            fileLink={fileUrl("ncc_certificate_path")}
          />
        )}

      {field("denomination") == DENOMINATION &&
        context.settingValue("pg_baptism_certificate") == "1" && (
          <InputField3
            label="Baptism certificate"
            value={field("baptism_certificate_path")}
            fileLink={fileUrl("baptism_certificate_path")}
          />
        )}
    </>
  );
};

export default CertificateDetails;
